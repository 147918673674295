import React from 'react';

import Layout from '../components/layout/layout';
import SEO from '../components/seo';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <main>
      <h1>{`There's nothing here!`}</h1>
      <p>{`Wrong turn, or I'm slacking... Could be either.`}</p>
    </main>
  </Layout>
);

export default NotFoundPage;
